import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const SvgComponent = ({ lengthText, color, ...props }) => (
	<svg
		id="scrollIndicator"
		width={200}
		height={200}
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="-20,-20,200,200"
		{...props}
	>
		<defs>
			<path d="M20,80a60,60 0 1,0 120,0a60,60 0 1,0 -120,0" id="textcircle" />
		</defs>
		{lengthText ? (
			<text>
				<textPath
					startOffset="0"
					xlinkHref="#textcircle"
					side="left"
					method="align"
					className="path"
					fill={color ? 'rgba(247, 245, 242, 1)' : ''}
				>
					<tspan>{props.text}</tspan>
					<tspan className="dot" dx="0.5%" dy="0.5%">
						•
					</tspan>
				</textPath>
				<textPath
					startOffset="33.333%"
					xlinkHref="#textcircle"
					side="left"
					method="align"
					className="path"
					fill={color ? 'rgba(247, 245, 242, 1)' : ''}
				>
					<tspan>{props.text}</tspan>
					<tspan className="dot" dx="0.5%" dy="0.5%">
						•
					</tspan>
				</textPath>
				<textPath
					startOffset="66.66%"
					xlinkHref="#textcircle"
					side="left"
					method="align"
					className="path"
					fill={color ? 'rgba(247, 245, 242, 1)' : ''}
				>
					<tspan>{props.text}</tspan>
					<tspan className="dot" dx="0.5%" dy="0.5%">
						•
					</tspan>
				</textPath>
			</text>
		) : (
			<text>
				<textPath
					startOffset="0"
					xlinkHref="#textcircle"
					side="left"
					method="stretch"
					className="path"
					fill={color ? 'rgba(247, 245, 242, 1)' : ''}
				>
					<tspan>{props.text}</tspan>
					<tspan className="dot" dx="1%" dy="1%">
						•
					</tspan>
				</textPath>
				<textPath
					startOffset="50%"
					xlinkHref="#textcircle"
					side="left"
					method="stretch"
					className="path"
					fill={color ? 'rgba(247, 245, 242, 1)' : ''}
				>
					<tspan>{props.text}</tspan>
					<tspan className="dot" dx="1%" dy="1%">
						•
					</tspan>
				</textPath>
			</text>
		)}
	</svg>
);

const Arrow = ({ color, ...props }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={13.888} height={16.203} id="arrow" {...props}>
		<path
			fill={color ? 'rgba(247, 245, 242, 1)' : ''}
			data-name="Path 36"
			d="m13.699 10.047-6.365 6a.566.566 0 0 1-.78 0l-6.365-6a.619.619 0 0 1-.039-.85.564.564 0 0 1 .818-.041l5.4 5.09V.6a.584.584 0 0 1 .546-.6.64.64 0 0 1 .611.6v13.646l5.4-5.089a.564.564 0 0 1 .818.041.617.617 0 0 1-.044.849Z"
		/>
	</svg>
);

const StyledIndicator = styled.div`
	//cursor: pointer;
	align-items: center;
	bottom: 0;
	display: flex;
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	justify-content: center;
	left: 50%;
	position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
	transform: translate(-50%);
	z-index: 0;

	#scrollIndicator {
		height: 175px;
		width: 175px;

		@media (min-width: 768px) and (max-width: 1024px) {
			width: 150px;
			height: 150px;
		}
		@media (max-width: 767px) {
			width: 100px;
			height: 100px;
		}
	}
	#arrow {
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
	}
	svg {
		.dot {
			fill: rgb(175, 135, 92);
			font-size: 20px;
		}
	}
`;

const ScrollIndicator = ({ text, fixed, color, pageContext }) => {
	const activeLang = pageContext?.locale;
	const [lengthText, setLengthText] = useState(false);
	useEffect(() => {
		if (activeLang === 'en') {
			setLengthText(true);
		} else {
			setLengthText(false);
		}
	}, []);

	return (
		<StyledIndicator id="wrapIndicator" fixed={fixed} data-cursor="blend-mode-circle">
			<SvgComponent text={text} color={color} lengthText={lengthText} />
			<Arrow color={color} />
		</StyledIndicator>
	);
};

export default ScrollIndicator;
