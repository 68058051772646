import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import GetHtmlFromRedactorField from '../utils/getHtmlFromRedactorField';
import ScrollIndicator from '../components/ScrollIndicator/ScrollIndicator';
import { setScaleIntroAnimation } from '../state/scaleIntroAnim';
import { setMenuColor } from '../state/menuColor';
import { setIntroStartModule } from '../state/introStartModule';
import { setIntroLoadedOnce } from '../state/introLoadedOnce';
import { setIsDropdownMenu } from '../state/isDropdownMenuSlice';
import Seo from '../components/seo/Seo';

const StyledView = styled.section`
	background-color: ${({ theme }) => theme.colors.black};
	display: flex;
	height: calc(var(--vh, 1vh) * 100);
	position: relative;
	text-align: center;
`;
const StyledText = styled.span`
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fontFamily.primary};
	font-size: 150px;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	left: 50%;
	line-height: 185px;
	max-width: 68%;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	@media (min-width: 1025px) and (max-width: 1366px) {
		font-size: 120px;
		line-height: 155px;
	}
	@media (min-width: 768px) and (max-width: 1024px) {
		font-size: 100px;
		line-height: 145px;
		max-width: 100%;
		width: 100%;
	}
	@media (max-width: 767px) {
		font-size: 100px;
		line-height: 120px;
		max-width: 100%;
	}
`;
const StyledDescription = styled.span`
	color: ${({ theme }) => theme.colors.black};
	font-family: ${({ theme }) => theme.fontFamily.primaryItalic};
	font-size: 32px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 42px;
	max-width: 60%;
	opacity: 0;
	overflow: hidden;
	position: relative;
	text-align: center;

	&.text-desc-1 {
		margin-bottom: 30px;
		top: 50px;
	}
	&.text-desc-2-1 {
		top: 160px;

		@media (max-width: 1024px) {
			top: 100px;
		}
	}
	@media (min-width: 768px) and (max-width: 1024px) {
		max-width: 100%;
	}
	@media (max-width: 767px) {
		font-size: 20px;
		line-height: 36px;
		top: 180px;
		max-width: 100%;
		font-weight: ${({ theme }) => theme.fontWeight.bold};
	}
`;
const StyledDesc = styled.div`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.white};
	display: flex;
	flex-direction: column;
	height: calc(var(--vh, 1vh) * 100);
	height: 100vh;
	justify-content: center;
	overflow: hidden;
	padding: 40px 80px;
	position: relative;
	text-align: center;
	width: 100%;
	@media (min-width: 768px) and (max-width: 1024px) {
		padding: 32px 64px;
		height: calc(var(--vh, 1vh) * 80);
	}
	@media (max-width: 767px) {
		padding: 12px 24px;
		height: calc(var(--vh, 1vh) * 80);
	}
`;
const StyledMainPhoto = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
	display: flex;
	flex-direction: column;
	height: 100vh;
	object-fit: contain;
	overflow: hidden;
	width: 100%;

	@media (min-width: 768px) and (max-width: 1024px) {
		height: calc(var(--vh, 1vh) * 60);
		img {
			width: 100vw;
			object-fit: contain !important;
			height: 100%;
		}
	}
	@media (max-width: 767px) {
		height: calc(var(--vh, 1vh) * 50);
		img {
			width: 100vw;
			height: 100%;
		}
	}
	@media (min-height: 650px) and (min-width: 1023px) and (max-width: 1366px) {
		height: 100vh;
	}
	.gatsby-image-wrapper,
	.main-img {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
`;
const StyledImgWrapperMain = styled.div`
	height: 100%;
	overflow: hidden;
	position: relative;
	width: 100%;

	.reveal {
		background-color: ${({ theme }) => theme.colors.white};
		height: 100%;
		position: relative;
		width: 100%;
	}
`;
const StyledPhoto = styled.div`
	align-items: flex-end;
	background-color: ${({ theme }) => theme.colors.white};
	background-color: ${({ theme }) => theme.colors.white};
	display: flex;
	flex-direction: column;
	height: 100%;
	object-fit: contain;
	overflow: hidden;
	padding-bottom: 50px;
	position: relative;
	width: 100%;
	z-index: 105;
	@media (min-width: 1025px) and (max-width: 1600px) {
		padding-bottom: 40px;
	}
	@media (min-width: 768px) and (max-width: 1024px) {
		padding: 24px 64px 64px;
		align-items: center;
	}
	@media (min-width: 481px) and (max-width: 767px) {
		padding: 0 25px 60px;
		align-items: center;
	}
	@media (max-width: 480px) {
		padding: 0 25px 25px;
		align-items: center;
	}
	.reveal-right {
		background-color: ${({ theme }) => theme.colors.white};
		height: 100%;
		position: absolute;
		right: 10vw;
		top: 0;
		width: 60%;
		@media (max-width: 1024px) {
			width: 100%;
			right: 0;
		}
	}
	.gatsby-image-wrapper img {
		left: initial;
		object-fit: contain !important;
		overflow: hidden;
		position: absolute;
		right: 10vw !important;
		width: 60%;
		@media (max-width: 1024px) {
			width: 100%;
			right: 0 !important;
		}
	}
`;

const StyledPhotoDesc = styled.div`
	align-items: flex-end;
	background-color: ${({ theme }) => theme.colors.white};
	display: flex;
	flex-direction: row;
	height: 100vh;
	padding: 80px 80px 150px;
	position: relative;
	z-index: 105;
	@media (min-width: 768px) and (max-width: 1024px) {
		padding: 57px 64px 64px;
		flex-direction: column;
		height: 100%;
	}
	@media (max-width: 767px) {
		flex-direction: column;
		padding: 24px 25px 70px;
		height: 100%;
	}
`;
const StyledInnerWrapper = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-end;
	position: relative;
	width: 100%;
	&.desc-wrapper {
		@media (min-width: 768px) and (max-width: 1024px) {
			align-items: flex-end;
			margin: 0 0 0;
		}
		@media (max-width: 767px) {
			align-items: flex-end;
			margin: 0;
		}
	}
`;
const StyledDescPhotoWrapper = styled.div`
	height: 100%;
	object-fit: contain;
	overflow: hidden;
	position: relative;
	width: 100%;
	.cover {
		background-color: ${({ theme }) => theme.colors.white};
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: calc(100% - 120px);
		z-index: 9;
		@media (max-width: 1024px) {
			width: 100%;
		}
	}
	.img-desc {
		bottom: 0;
		height: 100%;
		left: 0;
		opacity: 0;
		width: calc(100% - 120px);
		z-index: 6;
		@media (max-width: 1024px) {
			width: 100%;
		}
	}
`;
const StyledDescPhoto = styled.div`
	color: ${({ theme }) => theme.colors.black};
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 16px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 26px;
	padding-top: 20px;
	position: relative;
	text-align: left;
	z-index: 106;

	@media (min-width: 768px) and (max-width: 1024px) {
		padding-top: 10px;
		margin-bottom: 85px;
	}
	@media (max-width: 767px) {
		padding-top: 10px;
		margin-bottom: 45px;
	}
`;
const StyledDescText = styled.div`
	color: ${({ theme }) => theme.colors.black};
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 16px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 26px;
	max-width: 65%;
	position: relative;
	z-index: 106;
	@media (min-width: 1025px) and (max-width: 1600px) {
		width: 85%;
		max-width: 85%;
	}
	@media (min-width: 768px) and (max-width: 1024px) {
		width: 100%;
		max-width: 100%;
		height: 100%;
	}
	@media (max-width: 767px) {
		width: 100%;
		max-width: 100%;
	}
`;
const StyledIndicator = styled.div`
	bottom: 0;
	left: 50%;
	position: absolute;
	transform: translate(-50%);
	@media (max-width: 1024px) {
		height: 100px;
		width: 100px;
	}
`;

const FoodstoriesPage = ({ data: { foodstoriesData, allFile }, pageContext }) => {
	const allFoodstoriesImages = allFile?.edges;
	const pageData = foodstoriesData?.nodes?.[0] || '';
	const global = pageContext?.globals?.[0] || [];
	const seoGlobal = pageContext?.seoGlobal?.data?.datoCmsSite?.globalSeo || [];
	const seoData = pageData?.seoSettings || '';
	const textIndicator = global?.textIndicator || '';
	const startText = pageData?.text || '';
	const firstText = pageData?.firstText || '';
	const descPhotoText = pageData?.photoDesc || '';
	const secondText = pageData?.secondText || '';
	const descText = pageData?.desc || '';

	if (typeof window !== 'undefined') {
		const vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}
	const [widthWindow, setWidthWindow] = useState(0);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(setScaleIntroAnimation(true));
		dispatch(setIntroStartModule(false));
		dispatch(setMenuColor(true));
		dispatch(setIntroLoadedOnce(true));
		if (typeof window !== 'undefined') {
			const width = window?.innerWidth || 0;

			setWidthWindow(width);
			if (width < 1024) {
				dispatch(setIsDropdownMenu(true));
			}
		}
	}, []);

	return (
		<>
			<Seo seoGlobal={seoGlobal} seoData={seoData} />
			<StyledView className="section">
				<StyledText data-animation-type="text-second" className="anim text-second">
					{startText}
				</StyledText>
				<StyledIndicator id="indicatorBtn">
					<ScrollIndicator text={textIndicator} color pageContext={pageContext} />
				</StyledIndicator>
			</StyledView>
			<StyledDesc className="section anim" data-animation-type="header-description" id="scrollTo">
				<StyledDescription className="text-desc-1"> {firstText} </StyledDescription>
				<StyledDescription className="text-desc-2-1"> {secondText} </StyledDescription>
			</StyledDesc>
			<StyledMainPhoto className="section">
				<StyledImgWrapperMain className="anim" data-animation-type="one-image">
					<GatsbyImage
						alt="foodstories"
						className="img"
						image={getImage(allFoodstoriesImages.find((item) => item.node.name === '1').node)}
					/>
					<div className="reveal" />
				</StyledImgWrapperMain>
			</StyledMainPhoto>
			<StyledPhotoDesc className="section anim" data-animation-type="photo-desc">
				<StyledInnerWrapper>
					<StyledDescPhotoWrapper>
						<div className="cover" />
						<GatsbyImage
							alt="foodstories"
							className="img-desc"
							image={getImage(allFoodstoriesImages.find((item) => item.node.name === '2').node)}
						/>
					</StyledDescPhotoWrapper>
					<StyledDescPhoto>{descPhotoText}</StyledDescPhoto>
				</StyledInnerWrapper>
				<StyledInnerWrapper className="desc-wrapper">
					<StyledDescText
						dangerouslySetInnerHTML={GetHtmlFromRedactorField(descText)}
						className={widthWindow > 1024 ? 'desc' : ''}
					/>
				</StyledInnerWrapper>
			</StyledPhotoDesc>
			<StyledPhoto className="section last anim" data-animation-type="one-image-right">
				<GatsbyImage
					alt="foodstories-right"
					className="img-right"
					image={getImage(allFoodstoriesImages.find((item) => item.node.name === '3').node)}
				/>
				<div className="reveal-right" />
			</StyledPhoto>
		</>
	);
};

export default FoodstoriesPage;

export const query = graphql`
	query ($locale: String) {
		foodstoriesData: allDatoCmsFoodstoriesPage(filter: { locale: { eq: $locale } }) {
			nodes {
				photoDesc
				secondText
				text
				firstText
				desc
				seoSettings {
					title
					description
					twitterCard
					image {
						url
					}
				}
			}
		}
		allFile(filter: { sourceInstanceName: { eq: "foodstoriesGallery" } }) {
			edges {
				node {
					id
					name
					extension
					childImageSharp {
						gatsbyImageData
					}
				}
			}
		}
	}
`;
